import { filetypeextension, filetypemime } from 'magic-bytes.js'

import { UploadedFileType } from '@/graphql/generated/schemas'
import { exhaustiveCheck } from '@/utils/helpers'

import {
  FILENAME_INCORRECT_CHARACTERS,
  FILENAME_TOO_LONG,
  FILE_INCORRECT_EXTENSION,
  FILE_INCORRECT_EXTENSIONS,
  FILE_INCORRECT_TYPE,
  FILE_TOO_LARGE,
  UploadError,
} from './error'
import { fileToArrayBuffer, getExtension, haveCommonElement } from './helpers'

export interface ValidationOptionsI {
  maxFileNameLength?: number
  maxFileSizeInBytes?: number
}

const DEFAULT_MAX_FILENAME_LENGTH = 255

const ALLOWED_CHARS_REGEX = /^[a-zA-Z0-9 \-_.]+$/

export const validateFile = async (
  file: File,
  fileType: UploadedFileType,
  validationOptions?: ValidationOptionsI
): Promise<void> => {
  // File name validation
  const maxFileNameLength =
    validationOptions?.maxFileNameLength || DEFAULT_MAX_FILENAME_LENGTH
  validateFileName(file, maxFileNameLength)

  // File size validation (optional)
  if (validationOptions?.maxFileSizeInBytes) {
    validateFileSize(file, validationOptions?.maxFileSizeInBytes)
  }

  // File type validation: extension and MIME type
  switch (fileType) {
    case UploadedFileType.CsvFile:
      // We cannot use validation based on magic number for text-based files
      // Reference: <https://github.com/sindresorhus/file-type?tab=readme-ov-file#file-type>
      await validateFileType(file, ['csv'], ['text/csv'], false)
      break
    case UploadedFileType.FloorPlan:
      await validateFileType(file, ['png'], ['image/png'])
      break
    case UploadedFileType.IncidentImage:
      // Unused in the web-app, consider implementing after pulling that into shared module.
      break
    case UploadedFileType.StandardOperatingProcedure:
      await validateFileType(file, ['pdf'], ['application/pdf'])
      break
    case UploadedFileType.UserAvatar:
      // Unused
      break
    case UploadedFileType.UserPhoto:
      // Unused
      break
    case UploadedFileType.VideoClip:
      // Unused in the web-app, consider implementing after pulling that into shared module.
      break
    case UploadedFileType.VoiceClip:
      // Uploaded voice messages don't have file extension
      await validateFileType(file, '*', ['audio/wav', 'audio/x-wav'])
      break
    default:
      exhaustiveCheck(fileType)
  }
}

const validateFileType = async (
  file: File,
  // For '*' all extensions and MIME types are allowed
  allowedExtensions: string[] | '*',
  allowedMimes: string[] | '*',
  shouldUseMagicNumber = true
) => {
  let fileExtensions: string[]
  let fileMimes: string[]

  if (shouldUseMagicNumber) {
    // Determine file type based on "magic number"
    // Reference: <https://en.wikipedia.org/wiki/List_of_file_signatures>
    // This doesn't work for text-based files like CSV
    const arrayBuffer = await fileToArrayBuffer(file)
    const uint = new Uint8Array(arrayBuffer)

    fileExtensions = filetypeextension(uint)
    fileMimes = filetypemime(uint)
  } else {
    // Simpler form based on File API only
    fileExtensions = [getExtension(file.name)]
    fileMimes = [file.type]
  }

  // Validate file extension
  if (
    allowedExtensions !== '*' &&
    !haveCommonElement(allowedExtensions, fileExtensions)
  ) {
    if (allowedExtensions.length === 1) {
      throw new UploadError(FILE_INCORRECT_EXTENSION(allowedExtensions[0]))
    }
    throw new UploadError(FILE_INCORRECT_EXTENSIONS(allowedExtensions))
  }

  // Validate file MIME type
  if (allowedMimes !== '*' && !haveCommonElement(allowedMimes, fileMimes)) {
    throw new UploadError(FILE_INCORRECT_TYPE)
  }
}

const validateFileName = (file: File, maxLength: number) => {
  const fileName = file.name

  if (!ALLOWED_CHARS_REGEX.test(fileName)) {
    throw new UploadError(FILENAME_INCORRECT_CHARACTERS)
  }
  if (fileName.length > maxLength) {
    throw new UploadError(FILENAME_TOO_LONG(maxLength))
  }
}

const validateFileSize = (file: File, maxSizeInBytes: number) => {
  const fileSize = file.size

  if (fileSize > maxSizeInBytes) {
    throw new UploadError(FILE_TOO_LARGE)
  }
}
