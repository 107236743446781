import React from 'react'

import ReactPlayer from 'react-player'

interface IncidentNoteVideoPlayerIProps {
  videoUrl: string
}

export const IncidentNoteVideoPlayer = ({
  videoUrl,
}: IncidentNoteVideoPlayerIProps) => {
  return (
    <ReactPlayer
      controls
      data-testid='incident-note-video-player'
      height='100%'
      loop
      muted
      playing
      url={videoUrl}
      width='100%'
    />
  )
}
