import { useEffect, useState } from 'react'

import { Box } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'

import { IncidentIcon } from '@/components/icons'
import { AudioPlayer } from '@/components/ui'
import { useIncidentFeedItemsQuery } from '@/graphql/generated/hooks'
import { IncidentQuery } from '@/graphql/generated/operations'
import { IncidentActionType, SystemAction } from '@/graphql/generated/schemas'
import { mixpanel } from '@/utils/analytics'

import { MixpanelDataIProps } from '../../types/types'
import { FeedButtons } from './FeedButtons'
import { FeedItem } from './FeedItem'
import { FeedItemsSkeleton } from './FeedItemsSkeleton'
import { ActionHeader, ActionIcon, IncidentHeader } from './IncidentAction'
import { IncidentDescription } from './IncidentDescription'
import { ShowMore } from './ShowMore'

export enum NonActionItem {
  IncidentNote = 'IncidentNote',
  AudioMessage = 'AudioMessage',
  SystemAction = 'SystemAction',
}

interface FeedIProps {
  incident?: IncidentQuery['incident']
  mixpanelData?: MixpanelDataIProps
}

export const Feed = ({ incident, mixpanelData }: FeedIProps) => {
  const [items, setItems] = useState(null)
  const incidentId = incident?.id
  const device = incident?.devices[0]

  // This will ensure that feed items are frequently updated in Apollo cache
  useIncidentFeedItemsQuery({
    fetchPolicy: 'network-only',
    skip: !incidentId,
    variables: {
      id: incidentId,
    },
    pollInterval: 1000,
  })

  useEffect(() => {
    const actions = incident?.actions
    const events = incident?.events
    const audioMessages = incident?.audioMessages?.edges?.map((a) => a?.node)
    const notes = incident?.notes.edges?.map((a) => a?.node)

    if (actions && audioMessages && notes && events) {
      const incidentActions = actions.filter(
        (action) => action?.type !== IncidentActionType.Dispatch
      )
      const noteActions = notes.map((note) => ({
        nonActionType: NonActionItem.IncidentNote,
        ...note,
      }))
      const audioMessageActions = audioMessages.map((audioMessage) => ({
        nonActionType: NonActionItem.AudioMessage,
        ...audioMessage,
      }))
      const systemMessages = events
        .filter((item) =>
          Object.prototype.hasOwnProperty.call(item, 'systemMessage')
        )
        .map((systemMessage) => ({
          nonActionType: NonActionItem.SystemAction,
          ...systemMessage,
        })) as unknown as SystemAction[]
      setItems(
        [
          ...incidentActions,
          ...audioMessageActions,
          ...noteActions,
          ...systemMessages,
        ].sort(
          (a, b) =>
            new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
        )
      )
    }
  }, [incident])

  const trackExpandDescriptionClick = (eventType: 'more' | 'less'): void => {
    let eventName: string
    switch (eventType) {
      case 'more':
        eventName = 'Show More Button Pressed (Feed)'
        break
      case 'less':
        eventName = 'Show Less Button Pressed (Feed)'
        break
    }
    mixpanel.track(eventName, mixpanelData)
  }

  return (
    <Box align='flex-start' p='24px'>
      {!items ? (
        <FeedItemsSkeleton />
      ) : (
        <AnimatePresence>
          {items.map((a, i: number) => (
            <FeedItem
              actionType={a?.type || a?.nonActionType}
              avatar={a?.creator?.avatar?.url}
              avatarName={`${a?.creator?.firstName} ${a?.creator?.lastName}`}
              createdAt={a?.createdAt}
              heading={ActionHeader({
                actionType: a?.type || a?.nonActionType,
                username: `${a?.creator?.firstName} ${a?.creator?.lastName}`,
                roleName: a?.creator?.roleName,
                systemMessage: a?.systemMessage,
                technicianName: `${a?.deviceRepairOrder?.technician?.firstName} ${a?.deviceRepairOrder?.technician?.lastName}`,
              })}
              icon={ActionIcon(a?.type || a?.nonActionType)}
              index={i}
              key={i}
            >
              {a?.audioClip?.url && <AudioPlayer url={a?.audioClip?.url} />}
              {a?.message && (
                <ShowMore
                  handleShowLess={() => {
                    trackExpandDescriptionClick('less')
                  }}
                  handleShowMore={() => {
                    trackExpandDescriptionClick('more')
                  }}
                  message={a?.message}
                />
              )}
            </FeedItem>
          ))}
          <FeedItem
            actionType={null}
            createdAt={incident?.createdAt}
            heading={IncidentHeader(incident?.name)}
            icon={<IncidentIcon incidentType={incident?.type} size={30} />}
            index={items.length}
          >
            <IncidentDescription
              description={incident?.description}
              deviceId={device?.id}
              deviceName={device?.name}
              deviceType={device?.type}
              floorId={incident?.floor?.id}
              floorName={incident?.floor?.name}
              type={incident?.type}
            />
          </FeedItem>
        </AnimatePresence>
      )}
      <FeedButtons incident={incident} mixpanelData={mixpanelData} />
    </Box>
  )
}
