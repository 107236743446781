import { useFileSelect } from '../hooks/useFileSelect'
import { FeatureFlag, useFeatureFlags } from '@/hooks/useFeatureFlags'
import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Box, Flex, HStack } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'

import { Button, FormTextareaControl } from '@/components/ui'
import { useCreateIncidentNoteMutation } from '@/graphql/generated/hooks'
import { BUTTON_PRESS, mixpanel } from '@/utils/analytics'

import { MixpanelDataIProps } from '../../types/types'
import { ALLOWED_EXTENSIONS, MAX_FILES } from '../constants/constants'
import { AttachMediaButton } from './AttachMediaButton'
import { SelectedFilesPreview } from './SelectedFilesPreview'

interface AddNoteIProps {
  incidentId: string
  mixpanelData?: MixpanelDataIProps
  onActionComplete?: () => void
}

type FormValues = {
  comment?: string
}

export const AddNote = ({
  incidentId,
  mixpanelData,
  onActionComplete,
}: AddNoteIProps) => {
  const { shouldEnableIncidentNote } = usePermissions()
  const { formState, handleSubmit, errors, register, reset, watch } =
    useForm<FormValues>({
      defaultValues: { comment: '' },
    })
  const { isSubmitting } = formState

  const commentValue = watch('comment')

  const flags = useFeatureFlags()
  const isAttachMediaToIncidentNoteEnabled =
    flags?.[FeatureFlag.attachMediaToIncidentNote]

  const allowedExtensions = ALLOWED_EXTENSIONS.join(',')

  const [createIncidentNote] = useCreateIncidentNoteMutation()
  const {
    handleFileSelect,
    removeFile,
    previewFile,
    selectedFiles,
    setPreviewFile,
  } = useFileSelect()

  const onCancel = () => {
    mixpanel.track(`${BUTTON_PRESS} Add Note - Cancel`, mixpanelData)
    onActionComplete?.()
  }

  const { showError } = useToasts()

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    try {
      await createIncidentNote({
        variables: {
          input: {
            incidentId: incidentId,
            message: values.comment,
          },
        },
      })
      reset()
      mixpanel.track(`${BUTTON_PRESS} Add Note - Post`, mixpanelData)
      onActionComplete?.()
    } catch {
      showError()
    }
  }

  const handleClick = () => {
    mixpanel.track(`${BUTTON_PRESS} Add Note - Comment Textbox`, mixpanelData)
  }

  return (
    <Box mb={8} width='100%'>
      <form>
        <Box display='flex' flexDirection='column'>
          <FormTextareaControl
            data-testid='incidentDrawer_addNote_textarea'
            errorMessage={errors?.comment && errors?.comment.message}
            id='comment'
            isInvalid={!!errors?.comment}
            onClick={handleClick}
            placeholder='Add a comment...'
            textareaRef={register()}
          />
          {isAttachMediaToIncidentNoteEnabled && selectedFiles.length > 0 && (
            <SelectedFilesPreview
              previewFile={previewFile}
              removeFile={removeFile}
              selectedFiles={selectedFiles}
              setPreviewFile={setPreviewFile}
            />
          )}
          <AnimatePresence exitBeforeEnter>
            <Flex
              alignItems='center'
              justify={
                isAttachMediaToIncidentNoteEnabled
                  ? 'space-between'
                  : 'flex-end'
              }
              key='createIncidentNoteButtons'
              mt={5}
            >
              {isAttachMediaToIncidentNoteEnabled && (
                <AttachMediaButton
                  allowedExtensions={allowedExtensions}
                  handleFileSelect={handleFileSelect}
                  maxFiles={MAX_FILES}
                  selectedFiles={selectedFiles}
                />
              )}
              <HStack spacing='14px'>
                <Button
                  data-testid='incidentDrawer_addNote_cancel'
                  onClick={onCancel}
                  variant='secondary'
                >
                  Cancel
                </Button>
                <Button
                  data-testid='incidentDrawer_addNote_post'
                  isDisabled={!shouldEnableIncidentNote || commentValue === ''}
                  isLoading={isSubmitting}
                  loadingText='Submitting...'
                  onClick={handleSubmit(onSubmit)}
                >
                  Post
                </Button>
              </HStack>
            </Flex>
          </AnimatePresence>
        </Box>
      </form>
    </Box>
  )
}
